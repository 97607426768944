
import {
    ALL_GET_CUSTOMER
} from "../constant/ActionType";

export default function AllCustomerReducer(state = {
    allcustomerdata: [],
    lastPage: 1,

}, action) {
    switch (action.type) {
        case ALL_GET_CUSTOMER:
            return {
                ...state,
                allcustomerdata: action?.data?.data,
                lastPage: action?.data?.data?.last_page
                
            };
        default:
    }

    return state;
}