// project-imports
import components from "./components";
import dashboard from "./dashboard";
import ChangePasswordd from "./changePassword";
import * as url from "../store/constant/Endpoints";

import roles from "./roles";
import categories from "./categeory";
import companies from "./company";
import productz from "./product";
// import support from './support';
// import pages from './pages';
import randomcode from "./randomcode";
import permissions from "./permissions";
import { AES, enc } from "crypto-js";
import subCategories from "./subcategory";
import batch from "./batch";
import sms from "./sms";
import reports from "./reports";
import ColorShifting from "./colorShifting";
import customer from "./customer";



// ==============================|| MENU ITEMS ||============================== //

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    localStorage.clear();
    window.location = "/";
    window.location.reload();
  }
}

const AccessRoutes = JSON.parse(decryptedData);
console.log("hththth", AccessRoutes);
const menuItems = {
  items: [
    ...(AccessRoutes?.default_password === 0 ? [ChangePasswordd] : []),

    ...(AccessRoutes?.default_password !== 0
      ? [
          ...(dashboard ? [dashboard] : []),
          ...(companies ? [companies] : []),
          ...(categories ? [categories] : []),
          // ...(subCategories ? [subCategories] : []),
          ...(productz ? [productz] : []),
          ...(customer ? [customer] : []),
          ...(batch ? [batch] : []),
          ...(randomcode ? [randomcode] : []),
          ...(sms ? [sms] : []),
          ...(reports ? [reports] : []),
          ...(roles ? [roles] : []),
          ...(permissions ? [permissions] : []),
          ...(components ? [components] : []),
          ...(ColorShifting ? [ColorShifting] : []),
        ]
      : []
    ).filter(Boolean),
  ],
};

export default menuItems;
