import {
    ALL_BATCHES_BOTH_GET
} from "../constant/ActionType";

export default function AllBatchesBothGet(state = {
    viewallBatchgetAll: [],
}, action) {
    switch (action.type) {
        case ALL_BATCHES_BOTH_GET
        :
            return {
                ...state,
                viewallBatchgetAll: action?.data?.data,
            };

        default:
    }

    return state;
}