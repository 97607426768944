import * as url from "../constant/Endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { objectToQueryString } from "helper/urlquerystring";

import "react-toastify/dist/ReactToastify.css"; // Make sure you're importing the CSS file as well
const CryptoJS = require("crypto-js");

export default {
  fetchLoginUser: (payload, cb) => {
    if (payload.email === "" || payload.password === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
      password: payload.password,
      code: payload.code,
    };

    PostData(url.base_url + url.logInApii, data)
      .then((res) => {
        const encryptedToken = CryptoJS.AES.encrypt(
          res.data.data.token,
          url.encryptionSecret
        ).toString();

        const encryptedLoginData = CryptoJS.AES.encrypt(
          JSON.stringify(res?.data?.data),
          url.encryptionSecret
        ).toString();
        localStorage.setItem("token", encryptedToken);
        localStorage.setItem("Id", res.data.data.id);
        // localStorage.setItem("LoginData", JSON.stringify(res?.data?.data));
        localStorage.setItem("data", encryptedLoginData);

        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        if (res?.data?.data?.default_password === 1) {
          window.location.href = "/";
        } else {
          window.location.href = "/change-Password";
          console.log("Elseeeeeeeeeeeeeeeeeeeeeeeeeee=>>>>>>>>>>>>>>>>>>>>>>>");
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.data?.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  DiubleVerificationLoginn: (payload, cb) => {
    if (payload.email === "" || payload.password === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
      password: payload.password,
      code: payload.code,
    };

    PostData(url.base_url + url.loginAuthh, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        payload.setConditionRender(true);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.data?.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  ForgetPassword: (payload, cb) => {
    if (payload.email === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
    };

    PostData(url.base_url + url.ForgetPassword, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload.setLoading(false);
        setTimeout(() => {
          localStorage.setItem("email-forget", payload.email);

          window.location = "/otp-password";
        }, 1500);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  VerifyOTP: (payload, cb) => {
    if (
      payload.email === "" ||
      payload.password === "" ||
      payload.c_password === ""
    ) {
      payload.setLoading(false);

      return false;
    }
    if (payload.password !== payload.c_password) {
      toast.error("New Password & Confirm Password does not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
      otp: payload.otp,
      password: payload.password,
      c_password: payload.c_password,
    };

    PostData(url.base_url + url.VerifyOTP, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        localStorage.removeItem("email-forget");
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  // ExportCSVRadmonCOde: (payload, cb) => {
  //   if (payload.hasOwnProperty("paginate")) {
  //     delete payload.paginate;
  //   } else if (payload.hasOwnProperty("limit")) {
  //     delete payload.limit;
  //   }
  //   getData(
  //     `${url.base_url}${url.getRandomCodes}?paginate=all${
  //       payload?.random_code ? `&random_code=${payload.random_code}` : ""
  //     }${payload?.pair_value ? `&pair_value=${payload.pair_value}` : ""}${
  //       payload?.company ? `&company=${payload.company}` : ""
  //     }${payload?.batch_name ? `&batch_name=${payload.batch_name}` : ""}`
  //   )
  //     .then((res) => {
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       cb(err?.response?.status);
  //     });
  // },

  updateVerfiyLimit: (payload, cb) => {
    const NewData = new FormData();
    // NewData.append('id', payload.id)
    NewData.append("id", payload.product_id);
    NewData.append("limit", payload.limit);

    PostRegisterData(url.base_url + url.updateVerfiyLimit, NewData)
      .then((res) => {
        payload.setLoading(false);

        toast.success("Verify limit Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.reload();

        cb(res.data);
      })
      .catch((err) => {
        payload.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchRegisterBrandOwnerr: (payload, cb) => {
    const NewData = new FormData();
    // NewData.append('id', payload.id)
    NewData.append("company_id", payload.company_id);
    NewData.append("brand_name", payload.brand_name);
    NewData.append("brand_email", payload.brand_email);
    NewData.append("brand_logo", payload.brand_logo);
    NewData.append("brand_url", payload.brand_url);
    NewData.append("brand_phone", payload.brand_phone);
    NewData.append("brand_city", payload.brand_city);
    NewData.append("brand_address", payload.brand_address);
    NewData.append("created_by", payload.created_by);

    PostRegisterData(url.base_url + url.registerBrandOwner, NewData)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchCreateCompany: (payload, cb) => {
    PostRegisterData(url.base_url + url.createcompany, payload)
      .then((res) => {
        cb(res.data);
        toast.success("Company Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        setTimeout(() => {
          window.location.href = "/company/all-company";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CreateFetchRolee: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.registerRollsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  EditRole: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.editRoleAPI, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  AssignPermissionsShop: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.assignpermissionsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Permissions Assigned Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchRegisterFetchCategory: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.cateapplyregister, data)
      .then((res) => {
        toast.success("Category Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  CategorySubCreate: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.createSubcategory, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },
  CreateSMS: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.createSMS, data)
      .then((res) => {
        window.location = "/sms/recieved-sms-logs";
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  getAllPermissions: (payload, cb) => {
    getData(url.base_url + url.allpermissionsApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  GetRolePermissions: (payload, cb) => {
    getData(url.base_url + url.getRolePermissionsApi + `/${payload?.roleId}`)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  FetchAllBrands: (payload, cb) => {
    getData(url.base_url + url.getAllBrands)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  // Users

  HandleEditUserApi: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.edituserapis, data)
      .then((res) => {
        cb(res.data);
        toast.success("User Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchRegisterBrandUserrs: (payload, cb) => {
    PostRegisterData(url.base_url + url.registerbranduser, payload)
      .then((res) => {
        cb(res.data);
        if (res?.data.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.success("User Registered Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/user-management/users";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchAllUsersView: (payload, cb) => {
    getData(url.base_url + url.allUsersApi + "?page=" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchUsersStatusUpdateView: (payload, cb) => {
    getData(url.base_url + url.usersStatusUpdateApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  // Products

  HandleEditProductz: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.editProductApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Product Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        window.location.href = "/products/all-products";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  RegisterProductz: (payload, cb) => {
    PostRegisterData(url.base_url + url.registerProduct, payload)
      .then((res) => {
        cb(res.data);

        toast.success("Product Registered Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/products/all-products";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  ChangePassword: (payload, cb) => {
    const data = {
      current_password: payload.current_password,
      password: payload.password,
      default_password: payload.default_password,
    };

    PostRegisterData(url.base_url + url.ChangePassword, data)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
        toast.success("Password Change Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        localStorage.clear();
        window.location.href = "/";
        window.location = "/";
        setTimeout(() => {
          window.location.reload();
        }, 500);
        payload.setModalOpen(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          payload.setLoading(false);
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        payload.setLoading(false);
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  PostFAQSs: (payload, cb) => {
    const NewData = new FormData();
    NewData.append("content", payload.content);

    payload.content = payload.content.toString();
    PostRegisterData(url.base_url + url.UpdateFAQs, NewData)
      .then((res) => {
        cb(res.data);

        toast.success("FAQs Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);

        window.location.href = "/DomainParameter/FAQs";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          payload.setLoading(false);

          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        payload.setLoading(false);

        // console.warn(err);
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  PostTerms: (payload, cb) => {
    const NewData = new FormData();
    // NewData.append('id', payload.id)
    NewData.append("content", payload.content);

    payload.content = payload.content.toString();

    PostRegisterData(url.base_url + url.UpdateTerms, NewData)
      .then((res) => {
        cb(res.data);

        toast.success("Terms Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/DomainParameter/TermsAndPolicy";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        // console.warn(err);
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  async UpdateStatusCode(payload) {
    try {
      const response =
        payload.flag === 3
          ? await PostRegisterData(url.base_url + url.update_status_code, {
              flag: payload.flag,
              id: payload.id,
              serial_start_rng: payload.serial_start_rng,
              serial_end_rng: payload.serial_end_rng,
              status: payload.status,
            })
          : await PostRegisterData(url.base_url + url.update_status_code, {
              flag: payload.flag,
              id: payload.id,
              status: payload.status,
            });

      toast.success("Deactivate Code Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });

      payload.setLoading(false);
      window.location.href = "/random/all-random-code";
    } catch (error) {
      if (error?.response?.data?.message === "Unauthenticated.") {
        localStorage.clear();
        window.location = "/";
        window.location.reload();
      }
      // Handle other errors or show an error message
      toast.error(error?.response?.data?.message || "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });

      payload.setLoading(false);
    }
  },

  BatchProductSerialNo: (payload, cb) => {
    payload.setLoading(true);
    const apiUrl = `${url.base_url}${url.batchProductSerialNo}${payload.products}?serial_start_rng=${payload.serial_start_rng}&serial_end_rng=${payload.serial_end_rng}`;

    getData(apiUrl)
      .then((res) => {
        payload.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        payload.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  getBatchByProductID: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getBatchByProductID + payload.id)
      .then((res) => {
        payload?.setLoading(false);

        cb(res.data);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  CsvDownload: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.csv_url + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);
        const csvUrl = `${url.csv_file}${res.data}`;
        window.open(csvUrl);

        cb(res.data);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchAllActiveProducts: (payload, cb) => {
    getData(url.base_url + url.allProductActiveApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchAllProductz: (payload, cb) => {
    getData(
      url.base_url + url.allProductApi + objectToQueryString(payload?.paramms)
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchProductsStatusUpdateView: (payload, cb) => {
    getData(url.base_url + url.ProductStatusUpdateApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        // console.warn(err);
        // alert("Api error")
        // toast.error('Some Error Occoured2', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: 'toastStyle',
        // });
      });
  },

  FetchBrandOnesDetail: (payload, cb) => {
    getData(url.base_url + url.brandeditapi + "/" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchCompanyDetails: (payload, cb) => {
    getData(url.base_url + url.companydetailing + "/" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(err?.response?.status);
      });
  },

  FetchAllCategeory: (payload, cb) => {
    if (payload && payload.setLoading) {
      payload.setLoading(true);
    }

    getData(
      url.base_url + url.getsallcategeory + objectToQueryString(payload?.params)
    )
      .then((res) => {
        if (payload && payload.setLoading) {
          payload.setLoading(false);
        }
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (payload && payload.setLoading) {
          payload.setLoading(false);
        }

        cb(err?.response?.status);
      });
  },

  fetchALlDataExportCSVCategoeries: (payload, cb) => {
    if (payload?.hasOwnProperty("paginate")) {
      delete payload?.paginate;
    } else if (payload?.hasOwnProperty("limit")) {
      delete payload?.limit;
    }
    getData(
      `${url.base_url}${url.getsallcategeory}?paginate=all${
        payload?.name ? `&name=${payload.name}` : ""
      }${payload?.description ? `&description=${payload.description}` : ""}${
        payload?.company_name ? `&company_name=${payload.company_name}` : ""
      }${payload?.status ? `&status=${payload.status}` : ""}`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  FetchAllActiveCompanies: (payload, cb) => {
    getData(url.base_url + url.allDropdownActiveCompanies)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchAllActiveBrands: (payload, cb) => {
    getData(url.base_url + url.allDropdownActiveBrand)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  AllSubCategoryGet: (payload, cb) => {
    payload?.setLoading(true);

    getData(
      url.base_url +
        url.allsubcategroiess +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllSMSReplyGet: (payload, cb) => {
    payload?.setLoading(true);

    getData(url.base_url + url.smsReply + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);

        // console.warn(err);
        // alert("Api error")
        // toast.error('Some Error Occoured2', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: 'toastStyle',
        // });
      });
  },

  AllCompanies: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.companygetall + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchCSVCmpanyAll: (payload, cb) => {
    if (payload?.hasOwnProperty("paginate")) {
      delete payload?.paginate;
    } else if (payload?.hasOwnProperty("limit")) {
      delete payload?.limit;
    }

    getData(
      `${url.base_url}${url.companygetall}?paginate=all${
        payload?.company_code ? `&company_code=${payload.company_code}` : ""
      }${payload?.company_name ? `&company_name=${payload.company_name}` : ""}${
        payload?.company_city ? `&company_city=${payload.company_city}` : ""
      }${
        payload?.company_country
          ? `&company_country=${payload.company_country}`
          : ""
      }${
        payload?.company_email ? `&company_email=${payload.company_email}` : ""
      }${
        payload?.company_phone ? `&company_phone=${payload.company_phone}` : ""
      }${payload?.company_url ? `&company_url=${payload.company_url}` : ""}${
        payload?.role_name ? `&role_name=${payload.role_name}` : ""
      }${payload?.status ? `&status=${payload.status}` : ""}`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
        }
        cb(err?.response?.status);
      });
  },
  FetchCSVReportProblem: (payload, cb) => {
    if (payload?.hasOwnProperty("paginate")) {
      delete payload?.paginate;
    } else if (payload?.hasOwnProperty("limit")) {
      delete payload?.limit;
    }

    getData(
      `${url.base_url}${url.ReportProblem}?paginate=all${
        payload?.uid ? `&uid=${payload.uid}` : ""
      }${payload?.reason ? `&reason=${payload.reason}` : ""}${
        payload?.email ? `&email=${payload.email}` : ""
      }${payload?.batch_name ? `&batch_name=${payload.batch_name}` : ""}${
        payload?.product_name ? `&product_name=${payload.product_name}` : ""
      }`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
        }
        cb(err?.response?.status);
      });
  },

  AllBatches: (payload, cb) => {
    payload?.setLoading(true);
    console.log("sdsdsdsd",payload)
    getData(
      url.base_url + url.GetAllBatch + objectToQueryString(payload?.paramms)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },


  AllBatchesgettAll: (payload, cb) => {
    payload?.setLoading(true);
    console.log("sdsdsdsd",payload)
    getData(
      url.base_url + url.BatchesGetAll_api + objectToQueryString(payload?.paramms)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },


  GetAllCustmer: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.GetAllCustomer_api +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetActiveCustmer: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.GetActiveCustomer_api +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  StatusUpdateCustomer: (payload, cb) => {
    if (payload?.setLoading) {
      payload.setLoading(true);
    }
    const { id, status } = payload;
    PostRegisterData(
      `${url.base_url}${url.activeStatuscustomer_api}${id}/${status}` +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        if (payload?.setLoading) {
          payload.setLoading(false);
        }
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        if (payload?.setLoading) {
          payload.setLoading(false);
        }
        cb(err?.response?.status);
      });
  },

  AllcustommBatches: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.GetAllcustommBatch +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  viewSingleCustomBatch: (payload, cb) => {
    if (payload?.setLoading) {
      payload.setLoading(true);
    }
    getData(
      `${url.base_url}${url.ViewAllcustommBatch_api}${payload?.id}` +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        if (payload?.setLoading) {
          payload.setLoading(false);
        }
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        if (payload?.setLoading) {
          payload.setLoading(false);
        }
        cb(err?.response?.status);
      });
  },

  PostDataFetchGeting: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.prepostapii)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchMapingData: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.MapApi + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetFaqs: (payload, cb) => {
    getData(url.base_url + url.GetFAQs)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetTerms: (payload, cb) => {
    payload?.setLoading(true);

    getData(url.base_url + url.GETTerms)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllColorShift: (payload, cb) => {
    payload?.setLoading(true);

    getData(
      url.base_url + url.getColorShift + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchBrandStausUpdate: (payload, cb) => {
    getData(url.base_url + url.brandupdatestatusApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        // console.warn(err);
        // alert("Api error")
        // toast.error('Some Error Occoured2', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: 'toastStyle',
        // });
      });
  },

  FetchStatusChange: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.updatestatuscategory + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
    payload?.setLoading(false);
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  FetchStatusChangeSms: (payload, cb) => {
    getData(url.base_url + url.StatusSmsReply + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  SubCategoryUpdateStatus: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.subcategoryupdatestatus + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
    payload?.setLoading(false);
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  StatusUpdateCompanies: (payload, cb) => {
    getData(url.base_url + url.companystatusupdate + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  StatusUpdateBatches: (payload, cb) => {
    getData(url.base_url + url.batchStatusUpdate + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  HandleEditCompany: (payload, cb) => {
    const data = payload;
    PostRegisterData(url.base_url + url.companystatusupdate, data)
      .then((res) => {
        cb(res.data);

        toast.success("Company Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        window.location.href = "/company/all-company";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchBrandEditt: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.brandupdatestatusApi, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        // toast.error('Some Error Occoured2', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: 'toastStyle',
        // });
      });
  },

  fetchEditCategories: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.editingcategory + "/" + data.id, data)
      .then((res) => {
        toast.success("Category Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  fetchEditSMSReply: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.editsmsReply + "/" + data.id, data)
      .then((res) => {
        toast.success("SMS Reply Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  SubCategoryEditing: (payload, cb) => {
    const data = payload;

    PostRegisterData(
      url.base_url +
        url.subcategoryEdit +
        "/" +
        data.id +
        "?name=" +
        data.name +
        "&catId=" +
        data.catId +
        "&description=" +
        data.description
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  FetchAllRoleView: (payload, cb) => {
    getData(url.base_url + url.allRoleApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
      });
  },

  GetAllCounts: (payload, cb) => {
    console.log(payload);
    let apiUrl = url.base_url + url.getAllCounts;

    if (payload?.company_id) {
      const queryParams = { company_id: payload?.company_id };
      apiUrl += `${objectToQueryString(queryParams)}`;
    }
    console.log(apiUrl);

    getData(apiUrl)
      .then((res) => {
        cb(res?.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload?.setLoading(false);
      });
  },

  GetAllRandom: (payload, cb) => {
    payload?.setLoading(true);
    console.log(payload);
    const queryParams = payload?.params || { paginate: payload?.paginate };
    getData(
      url.base_url + url.getRandomCodes + objectToQueryString(queryParams)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  ExportCSVRadmonCOde: (payload, cb) => {
    if (payload.hasOwnProperty("paginate")) {
      delete payload.paginate;
    } else if (payload.hasOwnProperty("limit")) {
      delete payload.limit;
    }
    getData(
      `${url.base_url}${url.getRandomCodes}?paginate=all${
        payload?.random_code ? `&random_code=${payload.random_code}` : ""
      }${payload?.pair_value ? `&pair_value=${payload.pair_value}` : ""}${
        payload?.company ? `&company=${payload.company}` : ""
      }${payload?.batch_name ? `&batch_name=${payload.batch_name}` : ""}`
    )
      .then((res) => {
        cb(res.data);
        payload?.setExportCsvLoadings(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setExportCsvLoadings(false);

        cb(err?.response?.status);
      });
  },

  //SMS

  GetAllSMSLogs: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getSms + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchExportCSV: (payload, cb) => {
    if (payload.hasOwnProperty("paginate")) {
      delete payload.paginate;
    } else if (payload.hasOwnProperty("limit")) {
      delete payload.limit;
    }

    getData(
      `${
        url.base_url + url.SmsReport + objectToQueryString(payload?.params)
      }${objectToQueryString(payload?.params) ? "&" : "?"}paginate=all`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  //Report SMS

  GetAllSMSReport: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.SmsReport + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },
  GetProgressive: (payload, cb) => {
    getData(url.Progressive + objectToQueryString(payload?.params))
      .then((res) => {
        // payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        //     payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },
  //qrcode CSV

  FetchExportQRCSV: (payload, cb) => {
    if (payload.hasOwnProperty("paginate")) {
      delete payload.paginate;
    } else if (payload.hasOwnProperty("limit")) {
      delete payload.limit;
    }
    getData(
      `${
        url.base_url + url.QrcodeReport + objectToQueryString(payload?.params)
      }${objectToQueryString(payload?.params) ? "&" : "?"}paginate=all`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  //Report QRCODE

  GetAllQrcodeReport: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.QrcodeReport + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  GetReportProblem: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.ReportProblem + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  PostSMSReply: (payload, cb) => {
    // const NewData = new FormData();
    // NewData.append("title", payload.title);
    // NewData.append("message", payload.message);
    // NewData.append("urdu_message", payload.urdu_message);
    // NewData.append("company_id", payload.company_id);
    // NewData.append("product_id", payload.product_id);

    PostRegisterData(url.base_url + url.createsmsReply, payload)
      .then((res) => {
        cb(res.data);
        toast.success("SMS Reply Save Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/sms/get-sms-reply";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },

  PostRandomCode: (payload, cb) => {
    const NewData = new FormData();

    NewData.append("digit_count", payload.digit_count);
    NewData.append("user_id", payload.user_id);
    NewData.append("product_id", payload.product_id);
    NewData.append("company_id", payload.company_id);
    NewData.append("range", payload.range);
    NewData.append("segment", payload.segment);
    NewData.append("RequestID", payload.RequestID);

    // const encryptedUID = CryptoJS.AES.encrypt(
    //   payload.RequestID
    // ).toString();

    PostRegisterData(url.base_url + url.postRandomCodes, NewData)
      .then((res) => {
        cb(res.data);

        toast.success("Codes Generated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/random/all-random-code";
        }, 1500);

        const encryptedToken = CryptoJS.AES.encrypt(
          payload.RequestID,
          url.encryptionSecret
        ).toString();
        localStorage.setItem("uid", encryptedToken);
        // localStorage.setItem("uid", payload.RequestID);

        // localStorage.setItem("uid", encryptedUID);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        const parsedData = JSON.parse(err?.response?.data?.data || "{}");
        const errorMessage = parsedData.error || "undefined";
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },

  PostBatch: (payload, cb) => {
    const NewData = new FormData();

    NewData.append("batch_name", payload.batch_name);
    NewData.append("serial_start_rng", payload.serial_start_rng);
    NewData.append("serial_end_rng", payload.serial_end_rng);
    NewData.append("manufacturing_date", payload.manufacturing_date);
    NewData.append("expiry_date", payload.expiry_date);
    NewData.append("product_id", payload.product_id);
    NewData.append("remarks", payload.remarks);
    NewData.append("company_id", payload.company_id);

    PostRegisterData(url.base_url + url.postBatchAPI, NewData)
      .then((res) => {
        cb(res.data);
        toast.success("Batch Registered Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/batch/all-batch";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },
  PostUpdateBatch: (payload, cb) => {
    const NewData = new FormData();

    NewData.append("id", payload.id);
    NewData.append("batch_name", payload.batch_name);
    NewData.append("serial_start_rng", payload.serial_start_rng);
    NewData.append("serial_end_rng", payload.serial_end_rng);
    NewData.append("manufacturing_date", payload.manufacturing_date);
    NewData.append("expiry_date", payload.expiry_date);
    NewData.append("remarks", payload.remarks);
    NewData.append("product_id", payload.product_id);
    NewData.append("company_id", payload.company_id);

    PostRegisterData(url.base_url + url.postUpdateBatchAPI, NewData)
      .then((res) => {
        cb(res.data);
        toast.success("Batch Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/batch/all-batch";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },
  PostcustommBatch: (payload, cb) => {
    const NewData = new FormData();

    NewData.append("sale_no", payload.sale_no);
    // NewData.append("serial_start_rng", payload.serial_start_rng);
    // NewData.append("serial_end_rng", payload.serial_end_rng);
    // NewData.append("manufacturing_date", payload.manufacturing_date);
    // NewData.append("expiry_date", payload.expiry_date);
    NewData.append("product_id", payload.product_id);
    NewData.append("remarks", payload.remarks);
    NewData.append("company_id", payload.company_id);
    NewData.append("customer_id", payload.customer_id);
    payload.code.forEach((tag) => {
      NewData.append("code[]", tag); // Use "code[]" to denote an array
    });

    PostRegisterData(url.base_url + url.CustomBatch_API, NewData)
      .then((res) => {
        cb(res.data);
        toast.success("Custom Batch Registered Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/batch/custom-batch";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },
  PostcustomUpdateBatch: (payload, cb) => {
    const NewData = new FormData();

    NewData.append("id", payload.id);
    NewData.append("sale_no", payload.sale_no);
    // NewData.append("serial_start_rng", payload.serial_start_rng);
    // NewData.append("serial_end_rng", payload.serial_end_rng);
    // NewData.append("manufacturing_date", payload.manufacturing_date);
    // NewData.append("expiry_date", payload.expiry_date);
    NewData.append("remarks", payload.remarks);
    NewData.append("product_id", payload.product_id);
    NewData.append("company_id", payload.company_id);
    NewData.append("customer_id", payload.customer_id);
    payload.code.forEach((tag) => {
      NewData.append("code[]", tag);
    });

    PostRegisterData(url.base_url + url.postUpdatecustomBatch_API, NewData)
      .then((res) => {
        cb(res.data);
        toast.success("Custom Batch Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/batch/custom-batch";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },

  postCustomer: (payload, cb) => {
    payload.setLoading(true);
    const data = payload;
    PostRegisterData(url.base_url + url.Customer_API, data)
      .then((res) => {
        cb(res.data);
        toast.success("Customer Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/customer/all-customer";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },

  posteditCustomer: (payload, cb) => {
    payload.setLoading(true);
    const data = payload;
    PostRegisterData(url.base_url + url.Customer_edit_API, data)
      .then((res) => {
        cb(res.data);
        toast.success("Update Customers Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/customer/all-customer";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },

  PostUpdateColorShift: (payload, cb) => {
    const NewData = new FormData();

    NewData.append("id", payload.id);
    NewData.append("title", payload.title);
    NewData.append("image", payload.image);
    NewData.append("subtitle", payload.subtitle);

    PostRegisterData(url.base_url + url.ColorShift, NewData)
      .then((res) => {
        cb(res.data);
        toast.success("ColorShift Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/DomainParameter/get-colorShifting";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // payload.setLoading(false);
      });
  },
};

function PostData(URL, data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(URL, data, config);
}

function PostRegisterData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${NewToekn}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };
  return axios.post(URL, data, config);
}

// axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
function getData(URL) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.get(URL, config);
}
