
import {
    ACTIVE_GET_CUSTOMER
   } from "../constant/ActionType";
   
   export default function ActiveCustomerAll(state = {
       activeCustomer: [],
   
   }, action) {
       switch (action.type) {
           case ACTIVE_GET_CUSTOMER:
               return {
                   ...state,
                   activeCustomer: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }